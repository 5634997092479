import React, { useEffect, useState } from 'react';
import { LayoutMobileNewVersion } from '@components/Structural/Goal/NewVersion/LayoutMobile';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import TypeRules from '@components/SmartSave/Create/TypeRules/TypeRules';
import DescriptionRule from '@components/SmartSave/Create/DescriptionRule/DescriptionRule';
import { TypeDestine } from '@components/Inputs/SingleSelectors/Services/TypeDestine';
import AmountAndFrequency from '@components/SmartSave/Create/AmountAndFrequency/AmountAndFrequency';
import { ButtonPrimary, ButtonText, Select } from '@components/Reusables';
import AdviceAndAmount from '@components/SmartSave/Create/AdviceAndAmount/AdviceAndAmount';
import AmountOfMovements from '@components/SmartSave/Create/AmountOfMovements/AmountOfMovements';
import SwitchPercentageAmount from '@components/SmartSave/Create/SwitchPercentageAmount/SwitchPercentageAmount';
import Automatic from '@components/SmartSave/Create/PAC/Automatic';
import PAC from '@components/SmartSave/Create/PAC/PAC';
import Successful from '@components/SmartSave/Successful/Successful';
import SuccessfulCard from '@components/SmartSave/Successful/SuccessfulCard/SuccessfulCard';
import { MONEY_BACKGROUND_DARK_GREEN_ICON } from 'images/GCP';
import SuccessfulItem from '@components/SmartSave/Successful/SuccessfulItem/SuccessfulItem';
import { Route } from '@interfaces';
import { navigate } from 'gatsby';
import { useGoals } from '@apollo';
import DeleteRuleModal from '@components/SmartSave/Delete/DeleteRule';

const CreateNewRule = () => {
  const classes = useStyles();
  const [isSelected, setSelected] = useState(0);
  const [newRuleStep, setNewRuleStep] = useState(0);
  const isMobile = useMediaQuery('(max-width: 1300px)');
  const [goalValue, setGoalValue] = useState<number>(0);
  const [targetOptions, setTargetOptions] = useState([
    { value: 'null', name: 'No hay objetivo' },
  ]);
  const { goals } = useGoals();

  function getTargetOptions(targetData) {
    const targetArray = [];

    targetData.forEach((element) => {
      targetArray.push({ value: element.id, name: element.name });
    });

    setGoalValue(0);

    return targetArray;
  }
  const [isOpenDeleteRule, setOpenDeleteRule] = useState(false);

  useEffect(() => {
    setTargetOptions(getTargetOptions(goals));
  }, [goals]);

  return (
    <LayoutMobileNewVersion
      onBackClick={() => {
        navigate(Route.smartSaveDetails);
      }}
      stepNumber={'0'}
      subtitle={'Editar regla'}
      className={classes.height}
      pageName={'Aporte APV'}
      noStep={!isMobile}
      onDeleteClick={() => {
        setOpenDeleteRule(true);
      }}
    >
      <div className={classes.grid}>
        <TypeRules setSelected={setSelected} isSelected={isSelected} />
        <DescriptionRule
          text={
            isSelected === 0 ? (
              <>
                Deposita automáticamente en tu objetivo un monto fijo cada
                cierto tiempo.{' '}
                <strong>Por ejemplo: ahorrar $1.000 todos los días.</strong>
              </>
            ) : isSelected === 1 ? (
              <>
                Redondea tu compra al siguiente mil y apórtalo a tu objetivo.
                <strong>
                  {' '}
                  Por ejemplo si compras un artículo por $2.650 se ahorrarán
                  $350 (redondeo a $3.000).
                </strong>
              </>
            ) : (
              <>
                Aporta a tu objetivo cada vez que hagas un movimiento con tu
                cuenta o tarjeta.{' '}
                <strong>
                  Por ejemplo: por cada movimiento menor a $50.000, aportarás
                  $1.000 a tu objetivo.
                </strong>
              </>
            )
          }
        />
        {isMobile && (
          <section className={classes.selectorDestine}>
            <div className="title">Destino</div>
            <div className={classes.selector}>
              <Select
                name="sort"
                value={goalValue}
                onChange={(e) => setGoalValue(e.target.value)}
                size={'large'}
                options={targetOptions}
              />
            </div>
          </section>
        )}
        <div
          className={`${classes.containerLeftDesktop} ${
            isSelected === 2 && classes.secondVersion
          }`}
        >
          {isSelected === 1 ? (
            <AdviceAndAmount />
          ) : (
            isSelected === 0 && <AmountAndFrequency />
          )}
          {!isMobile && (
            <section className={classes.selectorDestine}>
              <div className="title">Destino</div>
              <div className={classes.selector}>
                <Select
                  name="sort"
                  value={goalValue}
                  onChange={(e) => setGoalValue(e.target.value)}
                  size={'large'}
                  options={targetOptions}
                />
              </div>
            </section>
          )}
          {!isMobile && isSelected === 2 && (
            <AmountOfMovements
              className={classes.secondVersionAmountOfMovenets}
            />
          )}
        </div>
        {isMobile && isSelected === 2 && <AmountOfMovements />}
        {isSelected === 2 && (
          <SwitchPercentageAmount
            className={
              !isMobile &&
              isSelected &&
              classes.secondVersionSwitchPercentageAmount
            }
          />
        )}
        {isSelected !== 2 && (
          <PAC className={classes.containerPAC} items={specifications} />
        )}
      </div>
      {isSelected === 2 && !isMobile && (
        <PAC
          className={isSelected === 2 && classes.containerAllWidth}
          items={specifications}
        />
      )}
      <div className={classes.containerButtons}>
        <ButtonPrimary
          text="Guardar cambios"
          onClick={() => {
            navigate(Route.investments);
          }}
        />
        <ButtonText text="Cancelar" onClick={() => {}} />
      </div>{' '}
      {isOpenDeleteRule && (
        <DeleteRuleModal onClose={() => setOpenDeleteRule(false)}/>
      )}
    </LayoutMobileNewVersion>
  );
};
export default CreateNewRule;

const useStyles = makeStyles((theme) => ({
  height: {
    maxWidth: '100%',
    [theme.breakpoints.up(1300)]: {
      '& >section >div >div>div>div': {
        justifyContent: 'start',
      },
      '& >section >div >div >div div:nth-child(2)': {
        paddingLeft: '3.2rem',
        textAlign: 'start',
      },
    },
    '& >section': {
      height: '8rem',
    },
    '& .children': {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      minHeight: '80vh',
      justifyContent: 'space-between',
      marginTop: '1.5rem',
    },
    '& .header': {
      [theme.breakpoints.up(1300)]: {
        minWidth: '57.5rem',
      },
    },
  },
  secondVersion: {
    [theme.breakpoints.up(1300)]: {
      height: 'auto!important',
    },
  },
  secondVersionAmountOfMovenets: {
    '& >div': {
      margin: 0,
    },
  },
  containerLeftDesktop: {
    [theme.breakpoints.up(1300)]: {
      height: '7rem',
      width: '440px',
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },
  },
  containerAllWidth: {
    width: '80%',
    margin: '0 auto',
    maxWidth: '57.5rem',
    '& >div': {
      width: '600px',
      margin: '0 auto',
    },
    '& >ul': {
      maxWidth: '600px',
      margin: '0 auto',
    },
  },
  secondVersionSwitchPercentageAmount: {
    '>div': {
      height: '100%',
      gap: '2rem',
    },
  },
  background: {
    background: '#eaeaea',
    minHeight: '100vh',
    padding: '2rem 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  selectorDestine: {
    background: 'white',
    borderRadius: 8,
    width: '90%',
    margin: '0 auto',
    padding: '1rem 1.8rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    [theme.breakpoints.up(1300)]: {
      height: '7rem',
      width: '440px',
    },
    '& .title': {
      color: '#646464',
      fontFamily: 'Montserrat',
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      fontWeight: 600,
    },
  },
  containerPAC: {
    [theme.breakpoints.up(1300)]: {
      width: '440px',
    },
  },
  selector: {
    '& >div': {
      width: '100%',
    },
  },
  button: {
    background: '#e6f3f5',
    color: '#282828',
    width: '17.8rem',
    margin: '4rem auto 0',
    display: 'flex',
    '& span': {
      color: '#282828',
    },
  },
  containerButtons: {
    '& button': {
      width: '285px',
      textDecoration: 'none',
    },
    width: '285px',
    margin: '2rem auto 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '1rem',
    [theme.breakpoints.up(1300)]: {
      width: '57rem',
      flexDirection: 'row-reverse',
      justifyContent: 'end',
      gap: '0rem',
    },
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [theme.breakpoints.up(1300)]: {
      display: 'grid',
      gridTemplateColumns: '430px 430px',
      justifyContent: 'center',
      gap: '2rem',
    },
  },
}));

const items = [
  {
    id: 0,
    title: 'Viaje Amsterdam',
    subtitle: 'Destino',
  },
  {
    id: 1,
    title: 'Depósito automático',
    subtitle: 'Se hará un aporte automático a tu objetivo',
  },
  {
    id: 2,
    title: 'Aporte desde cuenta corriente',
    subtitle: '**** **** **** 1234',
  },
];

const specifications = [
  {
    id: 0,
    text: 'Nunca te cargaremos si no tienes saldo suficiente',
  },
  {
    id: 1,
    text: 'Rokin cobrará un 1% de comisión por transferencia. (Cada $ 1.000 CLP cobraremos $10 CLP)',
  },
];
